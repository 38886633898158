import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isUserAllowed } from 'utils/isUserAllowed';

export const ForbidenRoute: FC = () => {
  const navigate = useNavigate();
  const [shouldRender, setRender] = useState<boolean>(false);

  useEffect(() => {
    if (isUserAllowed()) {
      // User is allowed so send him him away of this error page
      navigate('/');
    } else {
      setRender(true);
    }
  }, [navigate]);

  if (!shouldRender) {
    return null;
  }

  return (
    <section className="absolute h-screen w-screen bg-white">
      <div className="absolute w-full h-full flex flex-col justify-center items-center">
        <p className="text-body text-9xl font-bold">403</p>
        <p className="text-primary text-3xl">Access denied</p>
      </div>
    </section>
  );
};
