import { User, UserManager, UserManagerSettings } from 'oidc-client-ts';
import { StartupableService } from 'services/StartupableService';

class AuthenticationService extends StartupableService {
  private _userManager: UserManager | undefined;
  private _user: User | undefined;

  constructor() {
    super('AuthenticationService');
    this._userManager = undefined;
    this._user = undefined;
  }

  protected async init(): Promise<void> {
    this._userManager = new UserManager(this.getOidcSettings());

    // Cleanup user details in memory after signout
    this._userManager.events.addUserSignedOut(() => {
      this._user = undefined;
    });

    // Refresh in-memory copy of the user
    this._userManager.events.addUserLoaded((u) => {
      this._user = u;
    });

    const user = await this._userManager.getUser();

    if (user !== null && !user.expired) {
      // User is already signed in with a valid token
      this._user = user;
    }

    return Promise.resolve();
  }

  public get isSignedIn() {
    return this._user !== undefined;
  }

  public async startSignIn(currentState?: string) {
    if (this._userManager === undefined) {
      return;
    }

    this._userManager.signinRedirect({ state: currentState });
  }

  public async finishSignIn(): Promise<string | undefined> {
    if (this._userManager === undefined) {
      return Promise.reject();
    }

    const signedInUser = await this._userManager.signinCallback();

    // If successfully authenticated, restore window location
    if (signedInUser && !signedInUser.expired) {
      let state = undefined;

      if (signedInUser.state && typeof signedInUser.state === 'string') {
        // Restore window location to the state value
        state = signedInUser.state;
      }

      this._user = signedInUser;
      return Promise.resolve(state);
    }

    return Promise.reject();
  }

  public signout() {
    return this._userManager?.signoutRedirect();
  }

  get accessToken() {
    return this._user?.access_token;
  }

  get user() {
    const u = {
      firstName: this.parseUserName(this._user?.profile.given_name ?? ''),
      lastName: this.parseUserName(this._user?.profile.family_name ?? ''),
      userId: Number(this._user?.profile.sub),
      email: this._user?.profile.email,
    };
    return u;
  }

  get isFeidekaiUser() {
    const roles = this._user?.profile['role'];
    if (Array.isArray(roles)) {
      return roles.includes('feidekai_user');
    }

    return false;
  }

  private parseUserName(name: string) {
    return name === '---' ? undefined : name;
  }

  private getOidcSettings(): UserManagerSettings {
    const authority = process.env.REACT_APP_FEIDEKAI_IDSRV_API;

    return {
      authority: authority ?? '',
      client_id: process.env.REACT_APP_IDSRV_CLIENT_ID ?? '',
      redirect_uri: `${window.location.origin}/${process.env.REACT_APP_IDSRV_REDIRECT_ROUTE}`,
      post_logout_redirect_uri: window.location.origin,
      response_type: 'code',
      scope: process.env.REACT_APP_IDSRV_SCOPE,
      loadUserInfo: true,
      automaticSilentRenew: true,
      // The library has some issues with its timers which can cause
      // multiple requests being fired to renew the token. Setting the
      // notification time to -1 seems to fix it ¯\_(ツ)_/¯
      accessTokenExpiringNotificationTimeInSeconds: -1,
      acr_values: `tenant:feidekai`,
    };
  }
}

export default new AuthenticationService();
