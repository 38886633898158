import {
  DefaultI18nNameSpace,
  loadTranslationsFor,
  SupportedLocale,
} from 'i18n';
import en from 'i18n/locales/en/pmi.web.backoffice.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { StartupableService } from 'services/StartupableService';

import { UnsupportedLocaleError } from './errors/UnsupportedLocaleError';

class TranslationsService extends StartupableService {
  constructor() {
    super('TranslationService');
  }

  protected init(): Promise<void> {
    // eslint-disable-next-line import/no-named-as-default-member
    return i18n
      .use(initReactI18next)
      .init({
        /**
         * i18next options
         */
        debug: false,
        fallbackLng: SupportedLocale.English,
        ns: DefaultI18nNameSpace,
        resources: {
          en: {
            [DefaultI18nNameSpace]: en,
          },
        },
        keySeparator: false,
        nsSeparator: false,
        /**
         * react-i18next options
         */
        react: {
          bindI18n: 'languageChanged',
        },
      })
      .then(() => {
        this.changeLanguage(SupportedLocale.English);
      });
  }

  public async changeLanguage(locale: string) {
    if (!Object.values(SupportedLocale).includes(locale as SupportedLocale)) {
      throw new UnsupportedLocaleError(locale);
    }

    const supportedLocale = locale as SupportedLocale;
    await this.addTranslationsFor(supportedLocale);
    // eslint-disable-next-line import/no-named-as-default-member
    return i18n.changeLanguage(supportedLocale);
  }

  private async addTranslationsFor(locale: SupportedLocale): Promise<void> {
    if (i18n.hasResourceBundle(locale, DefaultI18nNameSpace)) {
      return Promise.resolve();
    }

    const translations = await loadTranslationsFor(locale);
    i18n.addResourceBundle(
      locale,
      DefaultI18nNameSpace,
      translations,
      true,
      true
    );
  }
}

export default new TranslationsService();
