import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from 'services/auth/AuthenticationService';
import { isUserAllowed } from 'utils/isUserAllowed';

export const AuthenticatedRoute: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [isReady, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (!AuthenticationService.isSignedIn) {
      AuthenticationService.startSignIn();
    } else if (isUserAllowed()) {
      setReady(true);
    } else {
      navigate('/403');
    }
  }, [navigate]);

  if (isReady) {
    return <>{children}</>;
  }

  return null;
};
