import type { FC } from 'react';

export const LanguageIcon: FC<{ size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
  >
    <g
      strokeLinecap="round"
      strokeWidth="2"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
    >
      <line data-cap="butt" x1="12" y1="1" x2="12" y2="23"></line>
      <line data-cap="butt" x1="1" y1="12" x2="23" y2="12"></line>
      <ellipse cx="12" cy="12" rx="5.5" ry="11"></ellipse>
      <circle cx="12" cy="12" r="11"></circle>
    </g>
  </svg>
);
