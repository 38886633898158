import { SupportedLanguagesMap, SupportedLocale } from 'i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TranslationsService from 'services/translations/TranslationsService';

import { Icon, IconName } from './icons';

const styles = {
  container: 'flex relative items-center',
  selectInput:
    'absolute w-full h-full inset-0 appearance-none cursor-pointer opacity-0',
  selectOption:
    'ml-xs after:content-empty after:ml-xs after:border-4 after:border-transparent after:border-t-white after:absolute after:top-1/2 after:-translate-y-0.5',
};

export const LanguageSelector: FC = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const onLanguageUpdate = (newLocale?: SupportedLocale) => {
    if (newLocale === void 0 || newLocale == null) {
      return;
    }

    TranslationsService.changeLanguage(newLocale);
  };

  return (
    <div className={styles.container}>
      <Icon name={IconName.Language} />
      <span className={styles.selectOption}>
        {SupportedLanguagesMap[language as SupportedLocale]}
      </span>
      {/* Hide the select element so that the text width is dynamic with the content */}
      <select
        className={styles.selectInput}
        title="Language selector"
        onChange={(event) =>
          onLanguageUpdate(event?.target.value as SupportedLocale)
        }
        defaultValue={language}
      >
        {Object.values(SupportedLocale).map((supportedLocale) => (
          <option key={supportedLocale} value={supportedLocale}>
            {SupportedLanguagesMap[supportedLocale]}
          </option>
        ))}
      </select>
    </div>
  );
};
