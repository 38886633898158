import { SupportedLocale } from 'i18n/schemas/Locales';

export class MissingTranslationsError extends Error {
  constructor(locale: SupportedLocale, cause: Error) {
    super(
      `Cannot load translations for "${locale}". The JSON file seems to be missing.`,
      { cause }
    );
    this.name = 'MissingTranslationsError';
  }
}
