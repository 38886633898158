import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import AuthenticationService from 'services/auth/AuthenticationService';
import { isUserAllowed } from 'utils/isUserAllowed';

export const AuthRedirectRoute: FC = () => {
  const [isReady, setReady] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    AuthenticationService.finishSignIn().then(() => {
      if (isUserAllowed()) {
        setReady(true);
      } else {
        navigate('/403');
      }
    });
  }, [navigate]);

  if (!isReady) {
    return null;
  }

  return <Navigate to={'/'} replace />;
};
