import { Icon, IconName } from 'components/icons';
import { DropdownMenu } from 'components/menus/DropdownMenu';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthenticationService from 'services/auth/AuthenticationService';
import { execGet } from 'services/http/RequestUtils';

import { LanguageSelector } from './LanguageSelector';

const AppLegacy: FC = () => {
  const { t } = useTranslation();

  const [link, setLink] = useState<string | undefined>();

  useEffect(() => {
    execGet<string>(`${process.env.REACT_APP_BACKOFFICE_API}/pmo/link`).then(
      setLink
    );
  }, []);

  return (
    <div className="flex flex-col h-screen">
      {/* Page Header with the main actions */}
      <header className="bg-white p-sm flex flex-row justify-between items-center shadow">
        <span className="text-xl text-primary">Office</span>
        <nav>
          <DropdownMenu
            menuIcon={<Icon name={IconName.Person} />}
            menuTitle={AuthenticationService.user.firstName ?? ''}
            options={[
              {
                title: t('Sign out'),
                onClick: () => AuthenticationService.signout(),
              },
            ]}
          />
        </nav>
      </header>

      {/* BODY */}
      <section className="flex-grow my-2">
        {link && (
          <iframe
            className="w-full h-full bg-white rounded p-xs border-none"
            src={link}
            title="Structure Analyzer"
          ></iframe>
        )}
      </section>

      {/* FOOTER */}
      <footer className="bg-primary text-white p-sm">
        <LanguageSelector />
      </footer>
    </div>
  );
};

export default AppLegacy;
