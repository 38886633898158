import AppLegacy from 'components/AppLegacy';
import { AuthenticatedRoute } from 'components/AuthenticatedRoute';
import type { FC } from 'react';

export const RootRoute: FC = () => {
  return (
    <AuthenticatedRoute>
      <AppLegacy />
    </AuthenticatedRoute>
  );
};
