export enum SupportedLocale {
  English = 'en',
  Chinese = 'zh-Hans',
}

type SupportedLanguage = {
  [locale in SupportedLocale]: string;
};

export const SupportedLanguagesMap: SupportedLanguage = {
  [SupportedLocale.English]: 'English',
  [SupportedLocale.Chinese]: '中文',
};

export const DefaultI18nNameSpace = 'pmi.web.backoffice';
