import { MissingTranslationsError } from 'i18n/errors/MissingTranslationsError';
import { SupportedLocale } from 'i18n/schemas/Locales';

export async function loadTranslationsFor(locale: SupportedLocale) {
  try {
    const module = await import(`../locales/${locale}/pmi.web.backoffice.json`);
    return module.default;
  } catch (err) {
    throw new MissingTranslationsError(locale, err as Error);
  }
}
