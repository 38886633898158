import type { FC } from 'react';
import { useMemo } from 'react';

import { LanguageIcon } from './svgs/language';
import { PersonIcon } from './svgs/person';

interface IIconProps {
  /**
   * Determines if the icon should have a fill or be only outlined.
   */
  readonly name: IconName;
  readonly className?: string;
}

export enum IconName {
  Language,
  Person,
}

function getIcon(icon: IconName): JSX.Element {
  switch (icon) {
    case IconName.Language:
      return <LanguageIcon size={16} />;
    case IconName.Person:
      return <PersonIcon size={16} />;
  }
}

export const Icon: FC<IIconProps> = ({ name, className = '' }) => {
  const icon = useMemo(() => getIcon(name), [name]);

  return <span className={className}>{icon}</span>;
};
