import { SimpleButton } from 'components/buttons';
import type { MouseEvent as ReactMouseEvent, ReactNode } from 'react';
import { Component } from 'react';

import './DropdownMenu.scss';

interface IDropdownMenuProps {
  readonly menuIcon: ReactNode;
  readonly menuTitle: string;
  readonly options: ReadonlyArray<{
    readonly title: string;
    readonly onClick: () => void;
  }>;
}

interface IDropdownMenuState {
  readonly showMenu: boolean;
}

export class DropdownMenu extends Component<
  IDropdownMenuProps,
  IDropdownMenuState
> {
  public constructor(props: IDropdownMenuProps) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  public componentDidUpdate(
    prevProps: Readonly<IDropdownMenuProps>,
    prevState: Readonly<IDropdownMenuState>
  ): void {
    if (this.state.showMenu && !prevState.showMenu) {
      document.addEventListener('click', this.closeMenu);
    }
  }

  public render() {
    return (
      <div className="dropdown-menu--container">
        <SimpleButton
          onClick={this.toogleMenu}
          className="dropdown-menu--button"
        >
          {this.props.menuIcon}
          <span>{this.props.menuTitle}</span>
        </SimpleButton>
        {this.state.showMenu && (
          <menu className="dropdown-menu--options">
            {this.props.options.map((option, i) => {
              return (
                <div key={`${option.title}_${i}`}>
                  <SimpleButton
                    onClick={(event) => this.onOptionClick(event, i)}
                    className="text-gray"
                  >
                    {option.title}
                  </SimpleButton>
                </div>
              );
            })}
          </menu>
        )}
      </div>
    );
  }

  private onOptionClick = (event: ReactMouseEvent, optionIndex: number) => {
    event.preventDefault();
    this.props.options[optionIndex].onClick();
  };

  private toogleMenu = (event: ReactMouseEvent): void => {
    event.preventDefault();
    this.setState({ showMenu: !this.state.showMenu });
  };

  private closeMenu = (event: MouseEvent): void => {
    event.preventDefault();
    this.setState({ showMenu: false });
    document.removeEventListener('click', this.closeMenu);
  };
}
