import ReactDOM from 'react-dom/client';
import AuthenticationService from 'services/auth/AuthenticationService';
import TranslationsService from 'services/translations/TranslationsService';
import 'styles/globals.scss';

import { AppRouter } from './AppRouter';

export async function startApplication() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  await AuthenticationService.startup();
  await TranslationsService.startup();
  root.render(<AppRouter />);
}
