import cn from 'clsx';
import { BasicButton } from 'components/buttons/BasicButton';
import type { IButtonProps } from 'components/buttons/schemas';
import type { FC } from 'react';

const styles = {
  hover: 'filter hover:text-primary-interaction',
  active: 'filter active:text-primary-interaction',
};

export const SimpleButton: FC<IButtonProps> = ({
  disabled,
  onClick,
  children,
  className,
}) => {
  return (
    <BasicButton
      onClick={onClick}
      disabled={disabled}
      className={cn(
        className ?? 'text-primary',
        'border-0 font-normal text-center',
        !disabled && styles.hover,
        !disabled && styles.active,
        disabled && 'cursor-not-allowed disabled',
        // Increase the area of interaction
        'inline-block relative z-1 p-xs -m-xs'
      )}
    >
      {children}
    </BasicButton>
  );
};
