import type { FC } from 'react';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import { ForbidenRoute } from 'routes/403';
import { AuthRedirectRoute } from 'routes/callback';
import { RootRoute } from 'routes/root';

const routesConf = [
  {
    path: '/',
    element: <RootRoute />,
  },
  {
    path: '/callback',
    element: <AuthRedirectRoute />,
  },
  {
    path: '/403',
    element: <ForbidenRoute />,
  },
  {
    path: '*',
    loader: () => redirect('/'),
  },
];

export const AppRouter: FC = () => {
  const router = createBrowserRouter(routesConf);

  return <RouterProvider router={router}></RouterProvider>;
};
