export abstract class StartupableService {
  public readonly name: string;

  private _isRunning: boolean;
  private _startupPromise: Promise<void> | undefined;

  constructor(name: string) {
    this.name = name;
    this._isRunning = false;
  }

  public async startup(): Promise<void> {
    // Ensure only one startup promise is created
    if (this._startupPromise !== undefined) {
      return this._startupPromise;
    }

    // Ensure the service is not started if already running
    if (this._isRunning) {
      return Promise.resolve();
    }

    this._startupPromise = this.init();
    try {
      await this._startupPromise;
      this._isRunning = true;
    } catch (err) {
      this._isRunning = false;
      throw err;
    } finally {
      this._startupPromise = undefined;
    }
  }

  protected abstract init(): Promise<void>;

  public get isRunning() {
    return this._isRunning === true;
  }
}
