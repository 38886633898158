import { getCommonAuthenticatedHeaders } from 'services/http/HeaderUtils';

import { ApiError } from './errors/ApiError';

export async function execGet<T>(
  apiEndpoint: string,
  signal?: AbortSignal
): Promise<T> {
  const response = await fetch(apiEndpoint, {
    method: 'GET',
    headers: getCommonAuthenticatedHeaders(),
    signal,
  });

  return handleFetchResponse(response);
}

async function handleFetchResponse(response: Response) {
  if (response.status === 204 && response.ok) {
    return;
  }

  const textResponse = await response.text();
  let jsonResponse;

  try {
    jsonResponse = await JSON.parse(textResponse);
  } catch (e) {
    //
  }

  if (response.ok) {
    return jsonResponse ?? textResponse;
  }

  if (Array.isArray(jsonResponse)) {
    const errMsg = jsonResponse
      .reduce((msg, err) => `${msg}\n${err?.detail}\n` ?? '\n', '')
      .slice(1);

    throw new ApiError(response.status, response.statusText, errMsg);
  }

  throw new ApiError(response.status, response.statusText, '');
}
