import AuthenticationService from 'services/auth/AuthenticationService';

export function isUserAllowed() {
  if (process.env.REACT_APP_ACTIVE_ENV === 'development') {
    // On dev environment, bypass the role and show only a banner
    // return true;
  }

  return (
    AuthenticationService.isSignedIn && AuthenticationService.isFeidekaiUser
  );
}
